import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { GlobalService } from './global.service';

@Injectable({
  providedIn: 'root'
})
export class VigilanteGuard implements CanActivate {
  login;
  constructor(public global: GlobalService, public nav: NavController){}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      this.login=JSON.parse(localStorage.getItem('log'));
      if(this.login == true || this.global.local == true ){
        console.log("Vigilante",this.login,"::",this.global.local)
        return true;
      }else{
        if(this.global.local != false){
          return true;
        }else{
          this.nav.navigateRoot(['/login']);
          return false;
        }
      }
  }

}
